import { PencilIcon } from '@heroicons/react/24/outline';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { TRANSFORMERS } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { useParams } from '@remix-run/react';
import { $createParagraphNode, $getRoot } from 'lexical';
import { useEffect, useState } from 'react';
import AutoLinkPlugin from '~/components/lexicalEditor/plugins/AutoLinkPlugin';
import CodeHighlightPlugin from '~/components/lexicalEditor/plugins/CodeHighlightPlugin';
import ImagePlugin from '~/components/lexicalEditor/plugins/ImagePlugin';
import ListMaxIndentLevelPlugin from '~/components/lexicalEditor/plugins/ListMaxIndentLevelPlugin';
import ToolbarPlugin from '~/components/lexicalEditor/plugins/ToolbarPlugin';
import { FloatingButtons } from './FloatingButtons';

export interface CustomTextEditorProps {
  html: string;
  canEdit: boolean;
  onChange: (state: string) => void;
  loading?: boolean;
}

export const CustomTextEditor: React.FC<CustomTextEditorProps> = ({ html, canEdit, onChange, loading }) => {
  const [editor] = useLexicalComposerContext();
  const [isEditing, setIsEditing] = useState(false);
  const params = useParams();

  // Every time page (route) has changed, restart editor state (set it in read mode)
  useEffect(() => {
    setIsEditing(false);
    editor.setEditable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      const children = root.getChildren();
      for (const node of children) {
        node.remove();
      }
      const paragraphNode = $createParagraphNode();
      root.append(paragraphNode);

      const parser = new DOMParser();
      const dom = parser.parseFromString(html, 'text/html');
      const selection = root.select();
      const nodes = $generateNodesFromDOM(editor, dom);
      selection.insertNodes(nodes);
      // editor.blur();
    });

    // TODO: fix scroll position when inserting nodes
    // Scroll the user to the top as a workaround for the scroll position bug
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
    // fixme: removing dependencies may cause a bug. They were removed to be able to hide the editor toolbar when
    // clicking save.
  }, [editor, html]);

  return (
    <div
      className={`w-full flex flex-col relative rounded-md ${isEditing ? ' border-2 border-blue-500' : 'mt-16'} ${
        canEdit ? 'hover:bg-slate-50' : 'px-4'
      }`}
    >
      {canEdit ? (
        <>
          {/* Floating buttons */}
          {!isEditing && (
            <FloatingButtons
              buttons={[
                {
                  action: () => {
                    setIsEditing(true);
                    editor.setEditable(true);
                  },
                  icon: PencilIcon,
                },
              ]}
            />
          )}

          <ToolbarPlugin loading={loading} setIsEditing={setIsEditing} isEditing={isEditing} />
          <div className='editor-inner w-full'>
            <RichTextPlugin
              contentEditable={<ContentEditable className='editor-input w-full' />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <ImagePlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <OnChangePlugin
              onChange={() => {
                editor.update(() => {
                  const htmlString = $generateHtmlFromNodes(editor, null);
                  onChange(htmlString);
                });
              }}
            />
          </div>
        </>
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
      )}
    </div>
  );
};

function Placeholder() {
  return <div className='editor-placeholder'></div>;
}
